<template>
  <div class="admin-view-container student-list-container" ref="studentContainer">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item style="width: 18%" label="姓名">
          <el-input placeholder="请输入内容" v-model="tableQuery.drvStudentName"></el-input>
        </el-form-item>
        <el-form-item style="width: 18%" label="身份证号">
          <el-input placeholder="请输入内容" v-model="tableQuery.drvStudentIdentityCard"></el-input>
        </el-form-item>
        <el-form-item label="会员状态" prop="contractStatus" style="width: 18%">
          <el-select v-model="tableQuery.contractStatus">
            <el-option label="全部" value=""></el-option>
            <el-option label="保障中" value="2"></el-option>
            <el-option label="金额超限" value="3"></el-option>
            <el-option label="完结" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品状态" prop="auditStatus" style="width: 18%">
          <el-select v-model="tableQuery.auditStatus">
            <el-option label="全部" value=""></el-option>
            <el-option label="审核中" value="10"></el-option>
            <el-option label="已通过" value="20"></el-option>
            <el-option label="未通过" value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: auto; width: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <admin-space size="medium" class="admin-methods-btns" align="center">
      <el-button
        icon="el-icon-plus"
        size="medium"
        @click="addServerHandler()"
        v-if="Date.parse(new Date()) / 1000 <= $store.state.user.drvSchoolEndTime">
        创建服务
      </el-button>
      <el-button icon="el-icon-upload2" size="medium" @click="importHandler()"> 批量导入</el-button>
    </admin-space>

    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column
          label="创建时间"
          prop="creationTime"
          :formatter="$formatter.date(true)"
          show-overflow-tooltip></el-table-column>
        <el-table-column label="姓名" prop="drvStudentName" show-overflow-tooltip width="120"></el-table-column>
        <el-table-column label="身份证号" prop="drvStudentIdentityCard" show-overflow-tooltip></el-table-column>
        <el-table-column label="业务类型" prop="usableVehicleType" show-overflow-tooltip></el-table-column>
        <el-table-column label="产品名称" width="220">
          <template>全科辅导会员（报销补考费）</template>
        </el-table-column>
        <el-table-column label="补考费赔付次数" prop="compensateNum">
          <template slot-scope="scope">
            <span v-if="$store.state.template.templateType == 2 || scope.row.resitAuditStatus == 10"> -- </span>
            <span v-else>{{ scope.row.compensateNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="会员服务状态" prop="contractStatus">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.contractStatus == 2
                  ? 'color: #34b377'
                  : scope.row.contractStatus == 3
                  ? 'color:#ff422d'
                  : scope.row.contractStatus == 4
                  ? 'color:#0075ff'
                  : ''
              ">
              {{ $dict.getData($dict.contractStatus, scope.row.contractStatus).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="产品服务状态" prop="auditStatus">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.auditStatus == 20
                  ? 'color: #34b377'
                  : scope.row.auditStatus == 30
                  ? 'color:#ff422d'
                  : scope.row.auditStatus == 10
                  ? 'color:#0075ff'
                  : ''
              ">
              {{ $dict.getData($dict.resitAuditStatusCopy, scope.row.auditStatus).label }}
              <el-tooltip
                class="item"
                effect="light"
                :content="scope.row.auditDescription"
                placement="top-end"
                v-if="scope.row.auditStatus == 30 && scope.row.auditDescription">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link size="medium" type="primary" @click="openPolicy(scope.row)" :disabled="!scope.row.canPolicyDetail">
                查看
              </el-link>
              <el-link
                size="medium"
                type="primary"
                @click="buyHandler(scope.row)"
                v-if="scope.row.canBuy && Date.parse(new Date()) / 1000 <= $store.state.user.drvSchoolEndTime">
                购买
              </el-link>
              <el-link size="medium" type="primary" @click="applyForHandler(scope.row)" v-if="scope.row.canApplyFor">
                申请补偿
              </el-link>
              <el-link size="medium" type="primary" @click="deleteHandler(scope.row)" v-if="scope.row.canDelete">
                删除
              </el-link>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {schStudentGetList, schStudentDelSchStudent, schSchoolGetExpenseTemplate} from '@/api/index';
import tableMinx from '@/mixin/table-page';

export default {
  mixins: [tableMinx(schStudentGetList)],
  data() {
    return {
      colKey: '',
      loading: false,
      dateValue: [],
      tableQuery: {
        drvSchoolCode: '',
        drvStudentName: '',
        drvStudentIdentityCard: '',
        usableVehicleType: '',
        resitAuditStatus: '',
      },
    };
  },
  created() {
    this.getGlobalData();
  },
  methods: {
    getGlobalData() {
      schSchoolGetExpenseTemplate().then((res) => {
        if (res.data.code == 0) {
          var type;
          var applyPrice;
          var dict = {
            A1Price: 0,
            A2Price: 0,
            A3Price: 0,
            B1Price: 0,
            B2Price: 0,
            C1Price: 0,
            C2Price: 0,
          };
          if (res.data.data.length == 1) {
            type = res.data.data[0].templateType;
          } else if (res.data.data.length == 2) {
            type = 3;
          } else {
            type = null;
          }
          res.data.data.forEach((item) => {
            if (item.templateType == 2) {
              applyPrice = parseInt(JSON.parse(item.templateCode).income);
            }
            if (item.templateType == 1) {
              const obj = JSON.parse(item.templateCode);
              obj.sub1.forEach((product1) => {
                dict[`${product1.vehicleType}Price`] += parseInt(product1.income);
              });
              obj.sub2.forEach((product2) => {
                dict[`${product2.vehicleType}Price`] += parseInt(product2.income);
              });
              obj.sub3.forEach((product3) => {
                dict[`${product3.vehicleType}Price`] += parseInt(product3.income);
              });
            }
          });
          this.$store.commit('template/setTemplateData', {
            templateType: type,
            templateData: res.data.data,
            templateDict: dict,
            applyPrice: applyPrice,
          });
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    async addServerHandler() {
      let vNode = this.$createElement((await import('./add-server')).default, {
        key: Math.random(),
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '创建服务',
        width: '500px',
      });
    },

    // 导入学员
    async importHandler() {
      if (Date.parse(new Date()) / 1000 > this.$store.state.user.drvSchoolEndTime) {
        var date = new Date(this.$store.state.user.drvSchoolEndTime * 1000);
        var years = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        this.$message({
          showClose: true,
          message: `会员服务业务已${years}/${month}/${day}于结束服务，不可导入学员。`,
          type: 'error',
          duration: 5000,
        });
        return false;
      }
      let vNode = this.$createElement((await import('./import.vue')).default, {
        key: Math.random(),
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '导入学员',
        width: '800px',
      });
    },

    // 清除
    deleteHandler(row) {
      this.$adminConfirm('用以永久删除该数据').then(() => {
        this.tableLoading = true;
        this.$utils.resolveRequest(
          schStudentDelSchStudent(row),
          this,
          () => {
            this.getTable();
          },
          () => {
            this.tableLoading = false;
          },
        );
      });
    },

    // 申请补偿
    async applyForHandler(row) {
      let vNode = this.$createElement((await import('./apply-for.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '上传申请',
        width: '600px',
      });
    },

    // 购买
    async buyHandler(row) {
      let vNode = this.$createElement((await import('./buy.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '购买服务',
        width: '600px',
      });
    },

    async openPolicy(row) {
      let vNode = this.$createElement((await import('./policy-detail')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '会员详情',
        width: '1200px',
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      // var nowTimestamp = Date.parse(new Date()) / 1000;
      data.forEach((item) => {
        if (item.auditStatus == 30) {
          item['canBuy'] = true;
        } else {
          item['canBuy'] = false;
        }

        if (item.contractStatus == 2 || item.contractStatus == 3 || item.contractStatus == 4) {
          item['canPolicyDetail'] = true;
        } else {
          item['canPolicyDetail'] = false;
        }

        if (item.contractStatus == 2 && item.auditStatus == 20) {
          item['canApplyFor'] = true;
        } else {
          item['canApplyFor'] = false;
        }

        if (item.auditStatus == 30) {
          item['canDelete'] = true;
        } else {
          item['canDelete'] = false;
        }
      });
      return data;
    },
  },
};
</script>

<style lang="scss">
.student-list-container {
  .red-font {
    color: $--color-danger;
  }
  .green-font {
    color: $--color-success;
  }
  .yellow-font {
    color: $--color-warning;
  }
  .blue-font {
    color: $--color-primary;
  }
}
</style>
